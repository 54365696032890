import useLockBodyScroll from 'hooks/useLockBodyScroll';

import { Box, Flex } from '@chakra-ui/react';
import { colors } from 'shared/utils/chakra-theme/colors';

const LoadingSpinner = () => {
	useLockBodyScroll();

	return (
		<Flex
			justify='center'
			align='center'
			h='100%'
			w='100%'
			pos='absolute'
			top='0'
			left='0'
			right='0'
			zIndex='100'
			bg='rgba(255, 255, 255, 0.5)'
		>
			<Box
				w='120px'
				h='120px'
				borderRadius='50%'
				border={`10px solid ${colors['primary-violet-40']}`}
				borderRightColor='transparent'
				display='inline-block'
				animation='0.75s linear 0s infinite normal both running spinner'
				bg='transparent !important'
			/>
		</Flex>
	);
};

export default LoadingSpinner;
