export const sendAmplitudeEventOnBack = (body, apiKey) => {
	fetch(
		`${process.env.REACT_APP_ANALYTICS_SERVER_URL}/amplitude/event/${apiKey}`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
				Authorization: process.env.REACT_APP_ANALYTICS_AUTH_HEADER,
			},
		},
	).catch(e => console.error(e));
};

export const sendAmplitudeUserPropertyOnBack = (body, apiKey) => {
	fetch(
		`${process.env.REACT_APP_ANALYTICS_SERVER_URL}/amplitude/user/${apiKey}`,
		{
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
				Authorization: process.env.REACT_APP_ANALYTICS_AUTH_HEADER,
			},
		},
	).catch(e => console.error(e));
};
