import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';

type Props = {
  title: string;
  description: string;
  onClose: () => void;
  img: string;
  isShort: boolean;
};

export const VerifyToast : FC<Props> = ({
  title,
  description,
  onClose,
  img,
  isShort,
}) => {
  const { t }= useTranslation();

  return (
    <Flex
      w={isShort ? '700px' : '808px'}
      minH="134px"
      pr="72px"
      pos="relative"
      bg={colors['primary-blue-20']}
      borderRadius="16px"
      boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
      align="center"
    >
      <Box p="32px 34px">
        <Image
          src={img}
          alt="camera"
          loading="eager"
        />
      </Box>

      <Flex direction="column" p="36px 0 24px">
        <Heading
          variant="primary-black-text"
          size="poppins24"
          lineHeight="34px"
          mb="12px"
        >
          {t(title)}
        </Heading>

        <Text
          size="poppins16normal"
          variant="primary-black-text"
          lineHeight="28px"
          letterSpacing="-0.016px"
        >
          {t(description)}
        </Text>
      </Flex>

      <Image
        pos="absolute"
        src="/images/create-story/close-circle.svg"
        alt="close icon"
        top="24px"
        right="24px"
        cursor="pointer"
        onClick={onClose}
      />
    </Flex>
  );
};
