import { USER_ROLE } from 'utils/enums/user-role.enum';
import { getFromLocalStorage } from 'utils/helpers/local-storage/localStorage';
import { RoleTypeResponse } from 'utils/types/user';

export function handleRole(): RoleTypeResponse {
	const access_token = getFromLocalStorage('access_token');
	const role = getFromLocalStorage('role');

	if (!access_token) {
		return {
			admin: false,
			writer: false,
			moderator: false,
		};
	}

	return {
		admin: role === USER_ROLE.ADMIN,
		writer: role === USER_ROLE.WRITER,
		moderator: role === USER_ROLE.MODERATOR,
	};
}
