import { AnalyticsEventType, AnalyticsParams } from '../../model/analytics';
import { useCallback, useEffect } from 'react';
import { ampEventWithEventProperty } from '../../utils/helpers/amplitude/amplitude';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_INFO } from 'entites/user/get-user-info.graphql';
import { handleSetTokens } from 'utils/helpers/log-in-log-out/handleSetTokens';
import { handleSetData } from 'utils/helpers/log-in-log-out/handleSetData';
import { handleLogOut } from 'utils/helpers/log-in-log-out/handleLogOut';

const Authenticate = () => {
	const navigate = useNavigate();
	const { access_token, refresh_token, type } = useQuery();
	const [getUserInfo] = useLazyQuery(GET_USER_INFO, {
		fetchPolicy: 'network-only',
	});

	const handleUserInfoGet = useCallback(async () => {
		try {
			if (access_token && refresh_token) {
				handleSetTokens({
					access_token,
					refresh_token,
				});

				const { data: userData } = await getUserInfo();

				if (userData.getUserInfo.isBlocked) {
					handleLogOut(false);
					
					navigate('/permission-denied', { state: { user_id: userData.getUserInfo.user_id}});
					return;
				}

				if (userData) {
					handleSetData({
						user_id: userData.getUserInfo.user_id,
						email: userData.getUserInfo.email,
						role: userData.getUserInfo.role,
					});
				}

				ampEventWithEventProperty(
					AnalyticsEventType.SIGN_IN_SUCCESS,
					AnalyticsParams.signInSuccess('google'),
				);
				// TODO!!! redo navigate
				navigate('/', { state: { type } });
				return;
			}
			throw new Error();
		} catch (error) {
			navigate('/auth/sign-in');
		}
	}, [access_token, getUserInfo, navigate, type]);

	useEffect(() => {
		handleUserInfoGet();
	}, [handleUserInfoGet]);

	return <></>;
};

export default Authenticate;
