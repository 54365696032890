import { Box, Button, Stack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FC, useCallback, useState } from 'react';
import { EmailField } from '../../LogIn/LogInForm/EmailField';
import { PasswordField } from '../../LogIn/LogInForm/PasswordField';
import { validateYupSchemaMultiErrors } from 'utils/helpers/yup/yupUtils';
import { signUpValidationSchema } from 'pages/Authorization/models';
import { useTranslation } from 'react-i18next';
import { LoginCredentials } from 'utils/types/user';

type Props = {
  credentials: LoginCredentials;
  handleSetCredentials: (data: LoginCredentials) => void;
  changeSignUpStep: (step: number) => void;
  isAcceptPolicy: boolean;
  handleShowPolicyError: () => void;
};

export const SignUpForm: FC<Props> = ({
  credentials,
  handleSetCredentials,
  changeSignUpStep,
  isAcceptPolicy,
  handleShowPolicyError,
}) => {
  const { t } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordShow = useCallback(() => {
    setPasswordShown(curr => !curr);
  }, []);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={credentials}
      validateOnBlur={true}
      validate={values =>
        validateYupSchemaMultiErrors(values, signUpValidationSchema)
      }
      onSubmit={values => {
        if (isAcceptPolicy) {
          handleSetCredentials(values);
          changeSignUpStep(2);
        } else {
          handleShowPolicyError();
        }
      }}
    >
    <Form style={{ width: '100%' }}>
      <Stack
        spacing="33px"
        width="100%"
      >
        <EmailField
          htmlForId="email"
          name="email"
        />

        <Box mb="75px">
          <PasswordField
            passwordShown={passwordShown}
            togglePasswordShow={togglePasswordShow}
            htmlForId="password"
            name="password"
            showError={true}
          />
        </Box>
      </Stack>

      <Box mb="12px">
        <Button
          type="submit"
          variant="defaultButton"
          className='signup-button'
          w="100%"
        >
          {t('Continue')}
        </Button>
      </Box>
    </Form>
  </Formik>
  );
};
