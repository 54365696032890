import amplitude from 'amplitude-js';
import {
	sendAmplitudeEventOnBack,
	sendAmplitudeUserPropertyOnBack,
} from '../../../api/amplitude';
import { AnalyticsEventType } from '../../../model/analytics';

const ampInst = amplitude.getInstance();
ampInst.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

export const ampEvent = async event => {
	const body = {
		event_properties: {},
		parameters: {
			user_id: window.user_id,
			event_name: event,
			device_id: ampInst.options.deviceId,
			time: Date.now(),
			email: window.userEmail,
			db_userId: window.db_userId,
		},
	};

	await sendAmplitudeEventOnBack(body, ampInst.options.apiKey);

	if (process.env.REACT_APP_NODE_ENV === 'dev') {
		ampInst.logEvent(event);
	}
};

export const ampEventFront = () =>
	ampInst.logEvent(AnalyticsEventType.PAGE_VIEW);

export const ampEventWithEventProperty = async (event, props) => {
	const body = {
		event_properties: props,
		parameters: {
			user_id: window.user_id,
			event_name: event,
			device_id: ampInst.options.deviceId,
			email: window.userEmail,
			db_userId: window.db_userId,
			time: Date.now(),
		},
	};

	await sendAmplitudeEventOnBack(body, ampInst.options.apiKey);

	if (process.env.REACT_APP_NODE_ENV === 'dev') {
		ampInst.logEvent(event, props);
	}
};

export const ampSetUserProperty = async (key, value) => {
	if (key === 'user_id') {
		ampInst.setUserId(window.user_id);
		return;
	}
	const body = {
		user_properties: {
			[key]: value,
		},
		parameters: {
			user_id: window.user_id,
			device_id: ampInst.options.deviceId,
			email: window.userEmail,
			db_userId: window.db_userId,
		},
	};

	await sendAmplitudeUserPropertyOnBack(body, ampInst.options.apiKey);

	if (process.env.REACT_APP_NODE_ENV === 'dev') {
		const identify = new amplitude.Identify().set(key, value);
		ampInst.identify(identify);
	}
};
