export enum NOTIFICATION_TYPE {
	// notifications for user
	WELCOME = 'WELCOME',
	LOCALIZATION = 'LOCALIZATION',
	EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
	REVENUE_UPDATE = 'REVENUE_UPDATE',
	REVENUE_SENT = 'REVENUE_SENT',
	WITHDRAW_REQUEST = 'WITHDRAW_REQUEST',
	WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW',
	REVIEW_CANCELED = 'REVIEW_CANCELED',
	REVIEW_STARTED = 'REVIEW_STARTED',
	REVIEW_CONTRACT_STARTED = 'REVIEW_CONTRACT_STARTED',
	REVIEW_CONTRACT_LEGAL_SUCCESS = 'REVIEW_CONTRACT_LEGAL_SUCCESS',
	REVIEW_CONTRACT_LEGAL_REJECT = 'REVIEW_CONTRACT_LEGAL_REJECT',
	REVIEW_CONTRACT_APPROVED = 'REVIEW_CONTRACT_APPROVED',
	REVIEW_CONTRACT_REJECT = 'REVIEW_CONTRACT_REJECT',
	NEW_BOOK_PUBLISHED = 'NEW_BOOK_PUBLISHED',
	REVIEW_REJECTED = 'REVIEW_REJECTED',
	REVIEW_PUBLISHED = 'REVIEW_PUBLISHED',
	DELETE_REJECT = 'DELETE_REJECT',
	DELETE_APPROVED = 'DELETE_APPROVED',
	DELETE_DRAFT = 'DELETE_DRAFT',
	CUSTOM = 'CUSTOM',
	PEN_NAME = 'PEN_NAME',

	// payment data moderation
	PAYMENT_DATA_REQUEST_CREATED = 'PAYMENT_DATA_REQUEST_CREATED',
	PAYMENT_DATA_REQUEST_CANCELED = 'PAYMENT_DATA_REQUEST_CANCELED',
	PAYMENT_DATA_REQUEST_APPROVED = 'PAYMENT_DATA_REQUEST_APPROVED',
	PAYMENT_DATA_REQUEST_REJECTED = 'PAYMENT_DATA_REQUEST_REJECTED',

	// notifications for admin
	NEW_CONTRACT_REQUEST = 'NEW_CONTRACT_REQUEST',
	NEW_CONTRACT = 'NEW_CONTRACT',
	CANCELED_REQUEST = 'CANCELED_REQUEST',
	NEW_BOOK_REVIEW_REQUEST = 'NEW_BOOK_REVIEW_REQUEST',
	BOOK_UPDATE_REQUEST = 'BOOK_UPDATE_REQUEST',
	BOOK_DELETE_REQUEST = 'BOOK_DELETE_REQUEST',
	USER_WITHDRAWAL_REQUEST = 'USER_WITHDRAWAL_REQUEST',
	NEW_PAYMENT_DATA_CHANGE_REQUEST = 'NEW_PAYMENT_DATA_CHANGE_REQUEST',
	NEW_PAYMENT_DATA_REQUEST_CANCELED = 'NEW_PAYMENT_DATA_REQUEST_CANCELED',
}
