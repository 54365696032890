import { gql } from '@apollo/client';

export const GET_USER_NOTIFICATIONS = gql`
	query GetUserNotifications {
		notifications: getUserNotifications {
			notification_id
			type
			title
			text
      reject_reason
      status
			created_by
			created_by_email
			created_by_id
      book {
        book_id
        title
      }
      createdAt
		}
	}
`;

export const GET_NEW_USER_NOTIFICATIONS = gql`
	query GetNewUserNotifications {
		notifications: getNewUserNotifications {
			notification_id
			type
			title
			text
      reject_reason
      status
			created_by
			created_by_email
			created_by_id
      book {
        book_id
        title
      }
      createdAt
		}
	}
`;

export const GET_SENDED_NOTIFICATIONS = gql`
	query GetSendedNotifications {
		items: getSendedNotifications {
			group_id
			createdAt
			is_global
			text
			title
			notifications {
				book {
					book_id
					title
				}
				user {
					user_id
					first_name
					last_name
				}
				status
			}
		}
	}
`;

export const DELETE_ADMIN_NOTIFICATIONS = gql`
	mutation DeleteAdminNotifications($ids: [String!]!) {
		result: deleteAdminNotifications(data: { ids: $ids })
	}
`;

export const MARK_CHECKED = gql`
	mutation MarkNotificationsChecked($ids: [String!]!) {
		result: markNotificationsChecked(data: { ids: $ids })
	}
`;

export const MARK_READED = gql`
	mutation MarkNotificationsReaded($ids: [String!]!) {
		result: markNotificationsReaded(data: { ids: $ids })
	}
`;

export const DELETE_NOTIFICATIONS = gql`
	mutation DeleteNotifications($ids: [String!]!) {
		result: deleteNotifications(data: { ids: $ids })
	}
`;

export const GENERATE_CUSTOM = gql`
	mutation GenerateCustomNotifications($data: GenerateCustomDto!) {
		result: generateCustomNotifications(data: $data)
	}
`;