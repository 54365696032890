import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { PLATFORM_NAME } from 'constants/common/PlatformName';
import { FC, useMemo } from 'react';
import { AUTH_STATE } from 'utils/enums/auth-state.enum';
import { AuthFormLayout } from '../AuthFormLayout';
import { useTranslation } from 'react-i18next';
import { LoginCredentials, SignUpCredentials } from 'utils/types/user';

type Props = {
	authState: AUTH_STATE;
  credentials: LoginCredentials;
  handleSetCredentials: (data: LoginCredentials) => void;
  signUpStep: number;
  isLoginLoading: boolean;
  isUserCreationLoading: boolean;
  changeSignUpStep: (step: number) => void;
  handleLogin: (values: LoginCredentials) => void;
	loginViaGoogleHandler: () => void;
  loginViaFacebookHandler: () => void;
	handleCreateAccount: (values: SignUpCredentials) => void;
}

export const AuthLayout: FC<Props> = ({
  authState,
  credentials,
  handleSetCredentials,
  signUpStep,
  isLoginLoading,
  isUserCreationLoading,
  changeSignUpStep,
  handleLogin,
	loginViaGoogleHandler,
	loginViaFacebookHandler,
	handleCreateAccount,
}) => {
  const { t } = useTranslation();
  const bgPath = useMemo(() => {
    switch (authState) {
      case AUTH_STATE.SIGN_UP:
        return '/images/authorization/sign_bg.png';
      
      case AUTH_STATE.RESET_PASSWORD:
        return '/images/authorization/reset_bg.png';
    
      default:
        return '/images/authorization/log_bg.png';
    }
  }, [authState]);

  return (
    <Flex
      h="100vh"
      w="100vw"
      position="relative"
    >
      <Image
        position="absolute"
        src={bgPath}
        alt="background"
        zIndex={-1}
        h="100vh"
        w="100vw"
        objectPosition="center"
        objectFit="cover"
        loading="eager"
      />
      <Flex
        justify="center"
        w="50%"
        h="100%"
        direction="column"
      >
        <Box h="708px" p="200px 0 0 112px">
          <Heading mb="12px">{PLATFORM_NAME}</Heading>

          <Text
            size="20"
            lineHeight="22px"
            letterSpacing="-0.408px"
          >
            {t('Writer\'s platform')}
          </Text>
        </Box>
      </Flex>

      <Flex
        w="50%"
        align="center"
        className="authorization-form-wrapper"
        borderRadius="5px"
      >
        <AuthFormLayout
          authState={authState}
          credentials={credentials}
          handleSetCredentials={handleSetCredentials}
          signUpStep={signUpStep}
					changeSignUpStep={changeSignUpStep}
          loginViaGoogleHandler={loginViaGoogleHandler}
          loginViaFacebookHandler={loginViaFacebookHandler}
          handleLogin={handleLogin}
          handleCreateAccount={handleCreateAccount}
          isLoginLoading={isLoginLoading}
          isUserCreationLoading={isUserCreationLoading}
        />
      </Flex>
    </Flex>
  );
};
