import { lazy, useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { handleRedirectRoute } from 'utils/helpers/handleRedirectRoute';
import { handleRole } from 'utils/helpers/handleRole';

const Requests = lazy(
	() => import('pages/ADMIN/Requests'),
);

const AdminProfile = lazy(
	() => import('pages/ADMIN/AdminProfile'),
);

const ContractSummary = lazy(
	() => import('pages/ADMIN/Requests/RequestsContent/ContractSummary'),
);

const StorySummary = lazy(
	() => import('pages/ADMIN/Requests/RequestsContent/StorySummary'),
);

const StoryRequestPreview = lazy(
	() => import('pages/ADMIN/Requests/RequestsContent/StoryPreview'),
);

const StorySplitPreview = lazy(
	() => import('pages/ADMIN/Requests/RequestsContent/ChaptersHardPreview'),
);

const Content = lazy(
	() => import('pages/ADMIN/Content'),
);

const StoryManager = lazy(
	() => import('pages/ADMIN/StoryManager'),
);

const StoryViewer = lazy(
	() => import('pages/ADMIN/StoryManager/StoryViewer'),
);

const CreateStory = lazy(
	() => import('pages/ADMIN/CreateStory'),
);

const EditStoryDetails = lazy(
	() => import('pages/ADMIN/EditStoryDetails'),
);

const EditChapters = lazy(
	() => import('pages/ADMIN/EditChapters'),
);

const SubmitStory = lazy(
	() => import('pages/ADMIN/SubmitStory'),
);

const AuthorManager = lazy(
	() => import('pages/ADMIN/AuthorManager'),
);

const ContractManager = lazy(
	() => import('pages/ADMIN/ContractManager'),
);

const EditAuthorProfile = lazy(
	() => import('pages/ADMIN/EditAuthorProfile'),
);

const CreateAuthor = lazy(
	() => import('pages/ADMIN/CreateAuthor'),
);

const ContractPreview = lazy(
	() => import('pages/ADMIN/ContractPreview'),
);

const Revenue = lazy(
	() => import('pages/ADMIN/Revenue'),
);

const SingleReport = lazy(
	() => import('pages/ADMIN/Revenue/RevenueContent/RevenuePeriods/SingleReport'),
);

const Inbox = lazy(
	() => import('pages/ADMIN/Inbox'),
);

const Notification = lazy(
	() => import('pages/ADMIN/Notification'),
);

const SendedNotification = lazy(
	() => import('pages/ADMIN/SendedNotification'),
);

const Localization = lazy(
	() => import('pages/ADMIN/Localization'),
);

const LocalizationEditor = lazy(
	() => import('pages/ADMIN/LocalizationEditor'),
);

const LocalizedStoryManager = lazy(
	() => import('pages/ADMIN/LocalizedStoryManager'),
);

const AdminRoutes = () => {
	const { admin: isUserAdmin, moderator: isUserModerator } = handleRole();
	const zeroRoute = useMemo(() => {
		if (isUserModerator) {
			return '/admin/requests?active=contracts&query=';
		}

		return '/admin/content';
	}, [isUserModerator]);

	const routes = useRoutes([
		{
			path: '*',
			element: <Navigate to={zeroRoute} />,
		},
		{
			path: '/content',
			children: [
				{
					path: 'story/:book_id',
					children: [
						{
							path: 'edit',
							element: <EditStoryDetails />,
						},
						{
							path: 'view-contract',
							element: <ContractPreview />,
						},
						{
							path: 'submit',
							element: <SubmitStory />,
						},
						{
							path: 'view/:chapter_id',
							element: <StoryViewer />,
						},
						{
							path: 'editor/:chapter_id',
							element: <EditChapters />,
						},
						{ index: true, element: <StoryManager /> },
					],
				},
				{
					path: 'story/create',
					children: [
						{ index: true, element: <CreateStory /> },
					],
				},
				{
					path: 'contract/:book_id',
					children: [
						{
							path: 'external',
							element: <ContractPreview />,
						},
						{
							path: 'inner',
							element: <ContractPreview />,
						},
						{ index: true, element: <ContractManager /> },
					],
				},
				{
					path: 'author/:author_id',
					children: [
						{
							path: 'edit',
							element: <EditAuthorProfile />,
						},
						{ index: true, element: <AuthorManager /> },
					],
				},
				{
					path: 'author/create',
					children: [
						{ index: true, element: <CreateAuthor /> },
					],
				},
				{ index: true, element: <Content /> },
			],
		},
		{
			path: '/revenue',
			children: [
				{
					path: 'periods',
					children: [
						{
							path: ':status/:year/:report_id',
							element: <SingleReport />,
						},
					],
				},
				
				{ index: true, element: <Revenue /> },
			],
		},
		{
			path: '/profile',
			children: [
				{ index: true, element: <AdminProfile /> },
			],
		},
		{
			path: '/inbox',
			children: [
				{ index: true, element: <Inbox /> },
				{ path: ':notification_id', element: <Notification /> },
				{ path: 'sent/:notification_id', element: <SendedNotification /> },
			],
		},
		{
			path: '/requests',
			children: [
				{
					path: 'contract/:book_id',
					children: [
						{
							path: 'external',
							element: <ContractPreview />,
						},
						{ index: true, element: <ContractSummary /> },
					],
				},
				{
					path: 'story/:request_id',
					children: [
						{ index: true, element: <StorySummary /> },
						{
							path: 'preview/:chapter_id',
							element: <StoryRequestPreview />,
						},
						{
							path: 'chapter-diff/:index',
							element: <StorySplitPreview />,
						},
					],
				},
				{ index: true, element: <Requests /> },
			],
		},
		{
			path: '/localization',
			children: [
				{ index: true, element: <Localization /> },
				{
					path: 'story/:book_id',
					children: [
						{ index: true, element: <LocalizedStoryManager /> },
						{
							path: ':locale/edit/:chapter_id',
							element: <LocalizationEditor />,
						},
					],
				}
			],
		},
	]);

	const redirectRoute = handleRedirectRoute() || '/auth/log-in';

	return (isUserAdmin || isUserModerator) ? routes : <Navigate to={redirectRoute} />;
};

export default AdminRoutes;
