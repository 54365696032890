import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { BaseFullProps, BaseShortProps, NotifToastProps } from '../../../../../utils/types/notifications/templates';
import { OneColorIcon } from '../../Story/Icon';
import { useNavigate } from 'react-router-dom';
import { EMAILS } from 'constants/email/Email';
import { UserContext } from 'context/UserContext';
import { getFromLocalStorage } from 'utils/helpers/local-storage/localStorage';
import { EmailTemplates } from 'constants/email/EmailTemplates';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';
import { Body } from '../../UI/Toast/Body';

export const RevenueSentToast: FC<NotifToastProps> = ({
  id,
  handleClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          color={'linear-gradient(180deg, #8DFC8A 0%, #34CE31 100%)'}
          img="/images/notifications/revenue/revenue.png"
        />
      </Box>

      <Body
        text={t('Your withdrawal request has been successfully confirmed! Your funds have been sent to the bank account you specified when signing the contract')}
        title={t('Revenue sent')}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const RevenueSentShort: FC<BaseShortProps> = ({
  id,
  isSelected,
  user_readed,
  createdAt,
  toggleSelect,
  handleOpenNotification,
}) => {
  const { t } = useTranslation();
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
      bg={bg}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          color={'linear-gradient(180deg, #8DFC8A 0%, #34CE31 100%)'}
          img="/images/notifications/revenue/revenue.png"
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {t('Revenue sent')}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          {t('Your withdrawal request has been successfully confirmed! Your funds have been sent to the bank account you specified when signing the contract')}
        </Text>
      </Flex>
    </Flex>
  );
};

export const RevenueSentFull: FC<BaseFullProps> = ({
  createdAt,
}) => {
  const { t } = useTranslation();
  const date = notificationDateFormatter(createdAt);
  const { user } = useContext(UserContext);

  const user_id = getFromLocalStorage('user_id');

  const handleCreateEmail = () => {
    const link = document.createElement('a');
    link.href = EmailTemplates.FINANCIAL_SUPPORT(user_id);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              color={'linear-gradient(180deg, #8DFC8A 0%, #34CE31 100%)'}
              img="/images/notifications/revenue/revenue.png"
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {t('Passion Team')}
            </Text>
            {' <support@passionapp.co>'}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="80px"
        h="80px"
        src="/images/notifications/revenue/success.png"
        loading="eager"
        alt="welcome"
      />

      <Box maxW="715px">
        <Text
          variant="primary-black-text"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          fontWeight={400}
          textAlign="center"
        >
          {t(`Hi, ${user?.first_name}!`)}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          textAlign="center"
        >
          {t('Your withdrawal request has been successfully confirmed!')}
        </Text>
      </Box>

      <Box maxW="600px" mb="20px">
        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          mb="32px"
          textAlign="center"
        >
          {t('Your funds have been sent to the bank account you specified when signing the contract')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          textAlign="center"
        >
          {t('Funds are typically deposited into the account within 24 hours. If the funds have not been deposited into the specified account by the end of this period as outlined in the contract, please contact support')}
          <br />
          <Text
            as="span"
            variant="primary-violet-100"
            size="poppins20normal"
            lineHeight="32px"
            textDecoration="underline"
            cursor="pointer"
            onClick={handleCreateEmail}
          >
            {EMAILS.support}
          </Text>
        </Text>
      </Box>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};
